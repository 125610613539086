import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LoadingModalComponent } from '../../app-layout/shared/loading-modal/loading-modal.component';
import { KycModalComponent, KYCModalInput } from '../../app-layout/shared/kyc-modal/kyc-modal.component';
import { JwtLegFiClaims } from '../../../services/auth/jwt-legfi-claims.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AdminService } from '../../../services/admin/admin.service';
import { AuthService } from '../../../services/auth/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuditHistoryComponent, AuditHistoryDialogInput } from '../../app-layout/shared/audit-history/audit-history.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AddHoaDialogComponent } from '../add-hoa-dialog/add-hoa-dialog.component';
import { GrowlerService } from '../../../services/growler.service';
import { IntercomDataConfig, IntercomService } from '../../../services/intercom.service';
import { SwitchEvent } from './header-switcher/header-switcher.component';
import { DropdownSearchEvent } from '../search/dropdown-search/dropdown-search.component';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { HostListenerService } from '../../../services/host-listener.service';
import { UnitsService } from '../../../services/units/units.service';
import { MatDialogSizes } from '../../../enums/mat-dialog-sizes.enum';
import { HeaderBarChange, HeaderBarEvents, HeaderBarListenerService } from '../../../services/header-bar-listener.service';
import { DepositBankAccountsService } from '../../../services/financial-accounts/deposit-bank-accounts.service';
import { DepositBankAccount } from '../../../models/entities/deposit-bank-account';
import moment from 'moment-timezone';
import environment from '../../../../environments/environment';
import { OrganizationDataService } from '../../../services/organization/organization-data.service';

export type Layout = 'admin' | 'app';

@UntilDestroy()
@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit, OnChanges, AfterViewInit
{
    @Input() layout: Layout;
    @Output() bannerShowing: EventEmitter<boolean> = new EventEmitter(false);
    @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    isInMobileMode = true;
    isLoggedIn = false;
    isShowingSwitcher = true;

    isShowingLoadingModal = false;
    loadingModalComponent = LoadingModalComponent;

    profileImage: string;

    orgLogo: string;
    isOrgLogoSet = false;

    isVisibleLogoTooltip = false;
    isDisplayingGlobalSearch = false;
    hasOrgViewPermission = false;

    jwt: JwtLegFiClaims;

    isResponseSuccess = false;
    hasSupportPermission = false;
    hasPmcFeatureAccess = false;

    readonly environment = environment;
    private uiLogo: string;

    constructor(
            private _observer: BreakpointObserver,
            private _router: Router,
            private _adminService: AdminService,
            private _authService: AuthService,
            private _activatedRoute: ActivatedRoute,
            private _unitsService: UnitsService,
            private _headerBar: HeaderBarListenerService,
            private _growler: GrowlerService,
            private _dialog: MatDialog,
            private _intercom: IntercomService,
            private _host: HostListenerService,
            private _depositBankAccountService: DepositBankAccountsService,
            private _organizationData: OrganizationDataService,
    ) {
    }

    get displayName() {
        if (this.jwt !== null) {
            return this.jwt.givenNames || (this.jwt.admin ? 'Admin' : 'Homeowner');
        }

        return 'User';
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('layout' in changes) {
            this.jwt = LegFiJwtService.read();
            this.hasPmcFeatureAccess = LegFiJwtService.featureEnabled('pmc.enabled');

            if (this.layout === 'app') {
                if (this.jwt !== null) {
                    this.runPermissionsChecks();
                }

                if (this.jwt && (this.jwt.superUser || this.jwt.admin)) {
                    this.isDisplayingGlobalSearch = true;
                }
            } else if (this.jwt !== null) {
                this.isLoggedIn = true;
            }

            this.uiLogo = '/assets/images/payhoa/hoa-management-software/payhoa-logo.svg';
            if (!this.isOrgLogoSet) {
                this.orgLogo = this.uiLogo;
            }

            if (this.jwt && !this.jwt.superUser && !this.jwt.admin) {
                // TODO: Replace with jwt unitIds when implemented
                // get member units for use in app
                this._unitsService.getMemberUnits(this.jwt.orgId, this.jwt.memberId)
                        .pipe(untilDestroyed(this))
                        .subscribe();
            }
        }
    }

    ngOnInit() {
        if (this.layout === 'app') {
            this.hasOrgViewPermission = LegFiJwtService.doesUserHaveModulePermission('organization', false);
            this._headerBar.onImageChange()
                    .pipe(untilDestroyed(this))
                    .subscribe((item: HeaderBarChange) => this.onImageChanged(item));
            this.getOrgDepositAccounts();

            this.hasSupportPermission = LegFiJwtService.doesUserHaveModulePermission(
                    'support',
                    false,
            );

            this._activatedRoute.queryParams.subscribe(
                    (params) => {
                        const success = params['verify'] || null;
                        if (success && success === 'success') {
                            this.isResponseSuccess = true;
                        }
                    },
            );

            this._router.events.pipe(untilDestroyed(this)).subscribe(event => {
                if (event instanceof NavigationEnd) {
                    const url = event.urlAfterRedirects;
                    if (url !== '/app/organization/dashboard') {
                        this.bannerShowing.emit(false);
                    } else {
                        this.getOrgDepositAccounts();
                    }

                    // Remove any chartjs tooltips that got left behind
                    // The tooltip node is inserted at the same level as lf-root below the body,
                    // so any approaches using @ViewChild will not be able to find it from any
                    // component we could put the directive on
                    document.getElementById('chartjs-tooltip')?.remove();
                }
            });
        }

        this._host.onScroll().pipe(untilDestroyed(this)).subscribe({
            next: () => {
                if (this.trigger?.menuOpen) {
                    this.trigger.closeMenu();
                }
            },
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._observer.observe(['(max-width: 945px)']).subscribe((res) => {
                this.isInMobileMode = res.matches;
            });

            let config: IntercomDataConfig = {
                user_id: this.jwt.memberId,
                name: this.jwt.givenNames + ' ' + this.jwt.familyName,
                admin: this.jwt.admin || this.jwt.superUser,
                super: this.jwt.superUser,
            };

            if (!this.jwt.superUser) {
                config = {
                    ...config,

                    // Can nonadmin user (with support permission) send messages?
                    can_send_message: this.hasSupportPermission,

                    // Do not pass company data with Super Users
                    company: {
                        id: this.jwt.orgId,
                        name: this.jwt.orgName,
                    },
                };
            }

            // 6/12/23 - disable intercom widget for users w/o support permission
            // costs have skyrocketed since the widget pushes up everyone that logs in
            if (this.hasSupportPermission) {
                this._intercom.boot(config);
            }
        });
    }

    sendIntercomMessage() {
        this._intercom.newMessage();
    }

    showNewHoaDialog(): void {
        this._dialog.open(AddHoaDialogComponent, {
            width: MatDialogSizes.SM,
        }).afterClosed().subscribe((orgId: number) => {
            if (orgId) {
                this.switchOrganization(orgId);
            }
        });
    }

    routeFromLogo(): void {
        if (!this.jwt) {
            window.location.href = 'https://www.payhoa.com';
            return;
        }

        if (this.jwt && (!this.jwt.admin || !this.jwt.superUser)) {
            // return to owner dashboard
            this._router.navigate(['/app/owner/dashboard']);
        } else {
            // return to org dashboard
            this._router.navigate(['/app/organization/dashboard']);
        }
    }

    getOrgDepositAccounts() {
        if (!this.jwt.admin) {
            return;
        }
        if (this._router.url !== '/app/organization/dashboard') {
            return;
        }
        if (this.isResponseSuccess) {
            return;
        }
        if (this.jwt.superUser && !this.jwt.memberId) {
            return;
        }
        this._depositBankAccountService.index()
                .subscribe({
                    next: (accounts: DepositBankAccount[]) => {
                        for (const bank of accounts) {
                            const twoWeeksFromNow = moment().add(2, 'weeks');
                            const currentlyDisabled = !!bank.disabledReason;
                            const deadlineUpcoming = bank.currentDeadline && bank.currentDeadline < twoWeeksFromNow;

                            if (currentlyDisabled || deadlineUpcoming) {
                                const data: KYCModalInput = {
                                    account: bank,
                                };
                                this._dialog.open(KycModalComponent, {
                                    maxWidth: MatDialogSizes.FULL,
                                    width: MatDialogSizes.FULL,
                                    height: MatDialogSizes.FULL,
                                    data: data,
                                });
                                break; // dont show more than 1 kyc dialog at once
                            }
                        }
                    },
                });
    }

    //noinspection JSMethodCanBeStatic
    doesUserHaveModulePermission(
            moduleName: string,
            requiresWrite: boolean,
    ): boolean {
        return LegFiJwtService.doesUserHaveModulePermission(
                moduleName,
                requiresWrite,
        );
    }

    runPermissionsChecks() {
        this.isLoggedIn = true;

        if (typeof this.jwt.profile !== 'undefined' && this.jwt.profile) {
            this.onImageChanged({
                type: HeaderBarEvents.ProfileImageChange,
                id: this.jwt.id,
            });
        }
        if (typeof this.jwt.logo !== 'undefined' && this.jwt.logo) {
            this.onImageChanged({
                type: HeaderBarEvents.OrgLogoImageChange,
                id: this.jwt.orgId,
            });
            this.isOrgLogoSet = true;
        } else {
            this.isVisibleLogoTooltip = true;
        }
    }

    onImageChanged(imageChange: HeaderBarChange) {
        const imageParts = [
            environment.S3URL,
            '[BUCKET]',
            '/',
            imageChange.id.toString(),
            '/',
            '[FILENAME]',
            '.png?',
            new Date().getTime(),
        ];

        // Adding to prevent lingering cached profile image after update.
        const noCache = '&nocache=' + new Date().getTime();

        switch (imageChange.type) {
            case HeaderBarEvents.ProfileImageChange:
                imageParts[1] = environment.UserProfileBucket;
                imageParts[5] = 'profile';
                this.profileImage = imageParts.join('') + noCache;
                break;
            case HeaderBarEvents.OrgLogoImageChange:
                this.isOrgLogoSet = true;
                imageParts[1] = environment.OrgLogoBucket;
                imageParts[5] = 'logo';
                this.orgLogo = imageParts.join('') + noCache;
                this.isVisibleLogoTooltip = false;
                break;
            case HeaderBarEvents.OrgLogoRemoval:
                this.isOrgLogoSet = false;
                this.orgLogo = this.uiLogo;
                break;
        }
    }


    logout() {
        this._authService.logout();
    }

    goToAdmin() {
        if (this.jwt) {
            if (this.jwt.superUser) {
                // noinspection JSIgnoredPromiseFromCall
                this._router.navigate(['/admin']);
            }
            if (this.jwt.impersonatedBy) {
                this._adminService.restore().subscribe({
                    next: () => this._router.navigate(['/admin']),
                    error: () => {
                        const msg = 'There was a problem restoring token. Log out and back in.';
                        console.error(msg);
                        alert(msg);
                    },
                });
            }
        }
    }

    stopImpersonation() {
        this.isShowingLoadingModal = true;
        this._adminService.restore().subscribe({
            next: () => window.location.reload(),
            error: () => {
                const msg = 'There was a problem restoring token. Log out and back in.';
                console.error(msg);
                alert(msg);
            },
        });
    }

    openAuditModal() {
        this._dialog.open(AuditHistoryComponent, {
            width: MatDialogSizes.LG,
            data: <AuditHistoryDialogInput>{
                model: null,
                id: null,
            },
        });
    }

    loadSiteMaster() {
        this._adminService.getMasterOrg().subscribe(response => this.switchOrganization(response.orgId));
    }

    switchFromDropdownSearch(dropdownSearchEvent: DropdownSearchEvent) {
        this.switchOrganization(dropdownSearchEvent.orgId, {}, dropdownSearchEvent.redirect);
    }

    switchFromRequest(switchEvent: SwitchEvent) {
        if (switchEvent.type === 'unit' && switchEvent.item.unit) {
            this.switchOrganization(switchEvent.item.id, {unitId: switchEvent.item.unit.id});
        } else {
            this.switchOrganization(switchEvent.item.id);
        }
    }

    switchOrganization(id: number, idParams: { [key: string]: number } = {}, redirectTarget?: any) {
        this.isShowingLoadingModal = true;
        this._organizationData.switchOrganization(id, idParams, redirectTarget).pipe(untilDestroyed(this)).subscribe({
            next: ([url, isExternalRedirect]: [string, boolean]) => {
                this.hasPmcFeatureAccess = LegFiJwtService.featureEnabled('pmc.enabled');
                this.isShowingLoadingModal = false;
                if (isExternalRedirect) {
                    window.location.href = url;
                } else {
                    // noinspection JSIgnoredPromiseFromCall
                    this._router.navigateByUrl(url, {skipLocationChange: true});
                }
            },
            error: () => {
                this._growler.oops('There was a problem switching current organization.');
                this.isShowingLoadingModal = false;
            }
        });
    }
}
