import { NgModule } from '@angular/core';
import { ToolbarSpacerDirective } from './toolbar-spacer.directive';
import { ToolbarTitleDirective } from './toolbar-title.directive';
import { ButtonSpacerDirective } from './button-spacer.directive';
import { LinkButtonDirective } from './link-button.directive';
import { PreventClickDirective } from './prevent-click.directive';
import { LoadingListDirective, LoadingListItemDirective } from './loading-list.directive';

@NgModule({
    imports: [],
    declarations: [
        ButtonSpacerDirective,
        LinkButtonDirective,
        PreventClickDirective,
        ToolbarSpacerDirective,
        ToolbarTitleDirective,
        LoadingListDirective,
        LoadingListItemDirective,
    ],
    exports: [
        ButtonSpacerDirective,
        LinkButtonDirective,
        PreventClickDirective,
        ToolbarSpacerDirective,
        ToolbarTitleDirective,
        LoadingListDirective,
        LoadingListItemDirective,
    ],
})
export class SharedDirectivesModule
{
}
