import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import environment from '../../environments/environment';

export interface HubSpotConfig {
    id: string;
    app_id: string;
}

@Injectable({
    providedIn: 'root',
})
export class HubSpotService {
    private config: HubSpotConfig;
    private _renderer: Renderer2;
    private _script: HTMLScriptElement = null;

    constructor(
            private _rendererFactory: RendererFactory2,
            @Inject(DOCUMENT) private document: Document
    ) {
        this._renderer = this._rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        this.config = {
            id: 'hs-script-loader',
            app_id: environment.HubSpot.appId,
        };
    }

    includePixel(url: string) {
        return url.indexOf('/sign-up') > -1 && url.indexOf('/sign-up/homeowner') === -1;
    }

    add() {
        if (environment.production && !this._script) {
            this.injectScript();
        }
    }

    remove() {
        if (environment.production && this._script) {
            this._renderer.removeChild(this.document.head, this._script);
            this._script = null;
        }
    }

    event(name: string, props: { [prop: string]: any; }) {
        if (environment.production) {
            const w = window as any;
            const _hsq = (w._hsq = w._hsq || []);
            _hsq?.push([
                name,
                props,
            ]);
        }
    }

    private injectScript() {
        // attach script to dom
        const script: HTMLScriptElement = this._renderer.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = this.config.id;
        script.src = `//js.hs-scripts.com/${this.config.app_id}.js`;
        this._script = script;

        this._renderer.appendChild(this.document.head, this._script);
    }
}
