import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, ViewContainerRef } from '@angular/core';
import { SidenavContentService } from './sidenav-content.service';

@Directive({
    selector: '[sidenavContentContainer]',
})
export class SidenavContentContainerDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}

@Directive({
    selector: `[sidenav-close], sidenav-close, [sidenavClose]`,
})
export class SidenavCloseDirective {
    constructor(private _sidenav: SidenavContentService) {
    }

    @HostListener('click', ['$event']) onClick(){
        this._sidenav.close();
    }
}

@Directive({
    selector: '[sidenav-label], sidenav-label, [sidenavLabel]',
})
export class SidenavLabelDirective
{
    @HostBinding('class') classValue = 'sidenav-label';
}


@Directive({
    selector: `[sidenav-content], sidenav-content, [sidenavContent]`,
})
export class SidenavContentDirective {
    @HostBinding('class') classValues = 'sidenav-content animated fadeIn';
}

@Directive({
    selector: `[sidenav-title], sidenav-title, [sidenavTitle]`,
})
export class SidenavTitleDirective implements AfterViewInit {
    constructor(private _el: ElementRef, private _sidenav: SidenavContentService) {
    }

    ngAfterViewInit(): void {
        this._sidenav._title = this._el;
    }
}

@Directive({
    selector: `[sidenav-actions], sidenav-actions, [sidenavActions]`,
})
export class SidenavActionsDirective implements AfterViewInit {
    constructor(private _el: ElementRef, private _sidenav: SidenavContentService) {
    }

    ngAfterViewInit(): void {
        this._sidenav._actions = this._el;
    }
}
