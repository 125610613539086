<ng-container *ngIf="showMenu">
    <button mat-icon-button disableRipple
            class="animated fadeIn"
            [attr.aria-label]="'PayHOA Notifications'"
            (click)="openNotifications()">
        <mat-icon matBadgeSize="small"
                  matBadgePosition="after"
                  [matBadge]="null"
                  [svgIcon]="'v2:fi-br-bell'">
        </mat-icon>
    </button>

    <button-spacer [size]="'sm'"></button-spacer>

    <app-avatar-block #appMenuTrigger="matMenuTrigger"
                      class="animated animated-delay fadeIn"
                      [isLoadingInitialData]="!(pmc$ | async)"
                      [initials]="(pmc$ | async)?.initials"
                      [class.mat-menu-active]="appMenuTrigger.menuOpen"
                      [attr.aria-label]="'PayHOA App Menu'"
                      [matMenuTriggerFor]="(pmc$ | async) ? appMenu : null">
        <span *ngIf="pmc$ | async as pmc" avatarTitle>{{ pmc.name }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
    </app-avatar-block>
    <mat-menu #appMenu="matMenu" xPosition="before" class="app-header-menu">
        <button class="menu-title"
                [class.add-hover]="jwt.superUser"
                [disabled]="!jwt.superUser"
                [routerLink]="['/pmc']"
                mat-menu-item>
            {{ (pmc$ | async)?.name }}
            <span>{{ 'All Communities' }}</span>
        </button>

        <mat-divider></mat-divider>

        <button *ngIf="jwt.memberId" [routerLink]="['/app/settings/user']" mat-menu-item>
            <mat-icon [svgIcon]="'v2:fi-br-settings-sliders'"></mat-icon>
            {{ 'Account Settings' }}
        </button>
        <button *ngIf="jwt.admin || jwt.superUser" [routerLink]="['/app/make-payment']" mat-menu-item>
            <mat-icon [svgIcon]="'v2:fi-br-dollar'"></mat-icon>
            {{ 'Make a Payment' }}
        </button>
        <button *ngIf="hasSupportPermission" (click)="sendIntercomMessage()" mat-menu-item>
            <mat-icon [svgIcon]="'v2:fi-br-flag'"></mat-icon>
            {{ 'Submit an Issue' }}
        </button>
        <button (click)="logout()" mat-menu-item>
            <mat-icon [svgIcon]="'v2:fi-br-logout'"></mat-icon>
            {{ 'Sign Out' }}
        </button>

        <mat-divider></mat-divider>

        <form class="header-organization-switcher" preventClick>
            <mat-form-field appearance="outline">
                <mat-icon [svgIcon]="'v2:fi-br-search'" matPrefix></mat-icon>
                <input #searchInput
                       type="text"
                       aria-label="Search"
                       matInput
                       [placeholder]="'Find Organization....'"
                       [formControl]="organizationSearch"
                       (keydown)="$event.stopPropagation()">
                <mat-spinner *ngIf="isSearchingOrganizations" [diameter]="15" matSuffix></mat-spinner>
            </mat-form-field>
            <cdk-virtual-scroll-viewport appendOnly itemSize="60" class="viewport">
                <button *cdkVirtualFor="let result of filteredResults"
                        (click)="goToOrganization(result); appMenuTrigger.closeMenu()"
                        mat-menu-item>
                    <app-avatar-block [initials]="result.initials">
                        <span avatarTitle>{{ result.name }}</span>
                        <span avatarDescription>{{ result.id }}</span>
                    </app-avatar-block>
                </button>
            </cdk-virtual-scroll-viewport>
        </form>
    </mat-menu>
</ng-container>
