import { NgModule } from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PmcLayoutComponent } from './pmc-layout.component';
import { pmcLayoutRouting } from './pmc-layout.routing';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderSearchModule } from './header/header-search/header-search.module';
import { SharedModule } from '../shared/shared.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SidenavContentModule } from './sidenav/sidenav-content/sidenav-content.module';
import { SidenavNavigationModule } from './sidenav/sidenav-navigation/sidenav-navigation.module';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { HeaderMenuModule } from './header/header-menu/header-menu.module';
import { NotificationsSidenavModule } from './notifications/notifications-sidenav/notifications-sidenav.module';
import { SharedDirectivesModule } from './shared/directives/shared-directives.module';

@NgModule({
    imports: [
        pmcLayoutRouting,
        SharedModule,
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        HeaderSearchModule,
        HeaderMenuModule,
        SidenavNavigationModule,
        SidenavContentModule,
        NotificationsSidenavModule,
        SharedDirectivesModule,
    ],
    declarations: [PmcLayoutComponent],
    exports: [],
})
export class PmcLayoutModule
{
}
