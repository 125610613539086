import { Component, OnInit } from '@angular/core';
import { PropertyManagementCompany } from '../../../../models/entities/property-management-company';
import { UntypedFormControl } from '@angular/forms';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { IntercomService } from '../../../../services/intercom.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { SimpleOrganization } from '../../../../models/entities/organization';
import { PropertyManagementCompanyService } from '../../../../services/property-management-company/property-management-company.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GrowlerService } from '../../../../services/growler.service';
import { debounceTime, map } from 'rxjs/operators';
import { OrganizationDataService } from '../../../../services/organization/organization-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SidenavContentService } from '../../sidenav/sidenav-content/sidenav-content.service';
import { NotificationsSidenavComponent } from '../../notifications/notifications-sidenav/notifications-sidenav.component';
import { filter, Observable } from 'rxjs';
import { PropertyManagementCompanyDataService } from '../../../../services/property-management-company/property-manager-company-data.service';

@UntilDestroy()
@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit
{
    pmc$: Observable<PropertyManagementCompany>;
    organizationSearch = new UntypedFormControl({ value: '', disabled: true });
    organizations: SimpleOrganization[] = [];
    isSearchingOrganizations = false;
    showMenu = false;

    jwt: JwtLegFiClaims;
    hasSupportPermission = false;

    constructor(
            private _propertyManagementCompanyService: PropertyManagementCompanyService,
            private _propertyManagementCompanyData: PropertyManagementCompanyDataService,
            private _organizationDataService: OrganizationDataService,
            private _authService: AuthService,
            private _activatedRoute: ActivatedRoute,
            private _sidenav: SidenavContentService,
            private _intercom: IntercomService,
            private _router: Router,
            private _growler: GrowlerService,
    ) {
    }

    get filteredResults() {
        return this.organizations.filter((organization) => organization.visible);
    }

    set filterTerm(term: string) {
        term = term ? term.trim().toLowerCase() : '';
        for (const organization of this.organizations) {
            if (term !== '') {
                let value = organization.name.trim().toLowerCase();
                value += organization.id;
                organization.visible = value.indexOf(term) > -1;
            } else {
                organization.visible = true;
            }
        }
    }

    ngOnInit() {
        this.jwt = LegFiJwtService.read();
        this.hasSupportPermission = LegFiJwtService.doesUserHaveModulePermission(
                'support',
                false,
        );

        this.pmc$ = this._propertyManagementCompanyData.pmc$;

        this._activatedRoute.paramMap.pipe(
                map((params) => +params.get('pmcId')),
                filter((pmcId) => !!pmcId),
                untilDestroyed(this),
        ).subscribe({
            next: () => {
                this.showMenu = true;
                this._propertyManagementCompanyService.getPropertyManagementOrganizationList().pipe(
                        untilDestroyed(this),
                ).subscribe({
                    next: (res) => {
                        this.organizations = res;
                        this.filterTerm = '';
                        this.organizationSearch.enable();
                    },
                    error: () => this._growler.oops('Unable to load organizations.'),
                });

                this.organizationSearch.valueChanges.pipe(
                        debounceTime(500),
                        untilDestroyed(this),
                ).subscribe({
                    next: (term) => {
                        this.filterTerm = term;
                    },
                });
            },
        });
    }

    goToOrganization(event: SimpleOrganization) {
        this._organizationDataService.switchOrganization(event.id).pipe(untilDestroyed(this)).subscribe({
            next: ([url, isExternalRedirect]: [string, boolean]) => {
                if (isExternalRedirect) {
                    window.location.href = url;
                } else {
                    // noinspection JSIgnoredPromiseFromCall
                    this._router.navigateByUrl(url, { skipLocationChange: true });
                }
            },
            error: () => {
                this._growler.oops('There was a problem switching current organization.');
            },
        });
    }

    sendIntercomMessage() {
        this._intercom.newMessage();
    }

    logout() {
        this._authService.logout();
    }

    openNotifications() {
        this._sidenav.open(NotificationsSidenavComponent);
    }
}
